<template>
  <div v-if="!loading">
    <b-row class="flex-column">
      <b-col class="d-flex align-items-center">
        <h2 class="font-weight-bold text-dark mr-2 mb-0">
          {{ $t('bankAccountData') }}
        </h2>
        <b-badge
          :variant="$helpers.paymentStatusColor(quote.payment_status)"
          class="h3 mb-0"
        >
          {{ $helpers.paymentStatusName(quote.payment_status) }}
        </b-badge>
      </b-col>
    </b-row>
    <b-row class="mt-0">
      <b-col
        sm="12"
        class="mt-2 text-center"
      >
        <h3 class="text-dark">
          {{ $t('paymentInfo') }}:
        </h3>
      </b-col>
    </b-row>
    <b-row class="mt-2 justify-content-center">
      <b-col
        sm="12"
        lg="3"
        class="text-center text-light"
      >
        <b-card class="bg-primary">
          <p>{{ payment.name }}</p>
          <p>{{ $t('bank') }}: {{ payment.bankName }}</p>
          <p class="mb-0">
            {{ $t('accountNumber') }}:
          </p>
          <p>{{ payment.accountNumber }}</p>
          <p class="mb-0">
            {{ $t('clabe') }}:
          </p>
          <p>{{ payment.clabe }}</p>
          <p class="mb-0">
            {{ $t('card') }}:
          </p>
          <p>{{ payment.card }}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="
        quote.payment_status === 'payment_pending' ||
          quote.payment_status === 'payment_rejected'
      "
      class="mt-2 justify-content-center"
    >
      <b-col
        sm="12"
        class="p-0 text-center"
      >
        <h3 class="text-dark">
          {{ $t('uploadReceipt') }}:
        </h3>
      </b-col>
      <b-col
        sm="12"
        lg="4"
        class="p-0 text-center mt-1"
      >
        <dropzone :height="'150'" />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        sm="12"
        class="d-flex justify-content-center mb-1"
      >
        <div class="d-flex flex-column">
          <b-button
            size="sm"
            variant="outline-primary"
          >
            <b-link
              target="_blank"
              :href="quote.payment_attachment"
            >
              <span class="h2 text-primary">
                {{ $t('invoice') }}
              </span>
              <feather-icon
                size="25"
                icon="ArrowDownIcon"
              />
            </b-link>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row
      v-if="newProviderFiles.length > 0"
      class="mt-2 justify-content-center"
    >
      <b-col>
        <files-table :files="newProviderFiles" />
      </b-col>
    </b-row>
    <b-row
      v-if="newProviderFiles.length > 0"
      class="mt-2 justify-content-center"
    >
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="upload()"
        >
          {{ $t('uploadTicket') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        sm="12"
        class="p-0 text-center"
      >
        <h3 class="text-primary">
          {{ $t('contactInfo') }}:
        </h3>
        <h3 class="text-dark">
          contacto@yoconstruyo.com.mx
        </h3>
        <span class="font-weight-bolder">
          {{ $t('updatedPrices') }} {{ new Date().getDate() }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        sm="12"
        class="mt-3 text-center"
      >
        <b-img
          :src="
            require('@/assets/images/yoconstruyo/imagen-datos-bancarios.png')
          "
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Dropzone from '@/components/dropzone/Dropzone.vue'
import FilesTable from '@/components/tables/FilesTable.vue'

export default {
  components: {
    Dropzone,
    FilesTable,
  },

  data() {
    return {
      attachment: null,
      loading: false,
      payment: {
        bankName: 'Santander',
        accountNumber: '65-50969732-6',
        clabe: '014580655096973264',
        card: '5579 0890 0447 7553',
        name: 'PLATAFORMA DIGITAL YOC SAPI DE CV',
      },
    }
  },

  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
    ...mapGetters('quotes', ['quote']),
    ...mapState(['apiUrl']),
  },

  created() {
    this.loading = true
    this.fetchQuote(this.$route.params.quoteId).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('quotes', ['fetchQuote', 'payQuote']),

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },

    upload() {
      this.getBase64(this.newProviderFiles[0]).then(data => {
        this.payQuote({
          quoteId: this.$route.params.quoteId,
          attachment: { data },
        }).then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('quotePayedInfo'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({
            name: 'quotes',
            params: { projectId: response.project_id },
          })
        })
      })
    },
  },
}
</script>

<style></style>
